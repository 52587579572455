.pr-readiness-tool {

  max-width: 800px;

  margin: 0 auto;

  padding: 20px;

  font-family: Arial, sans-serif;

}



.progress-bar {

  width: 100%;

  height: 10px;

  background-color: #e0e0e0;

  border-radius: 5px;

  margin-bottom: 20px;

  overflow: hidden;

}



.progress {

  height: 100%;

  background-color: #3788d8;

  border-radius: 5px;

  transition: width 0.3s ease-in-out;

}



.question-container {

  margin-bottom: 30px;

  text-align: center;

}



.form-field {

  margin-bottom: 20px;

}



label {

  display: block;

  margin-bottom: 10px;

  font-weight: bold;

}



input[type="text"],

input[type="email"],

input[type="tel"],

input[type="url"],

input[type="date"],

textarea,

select {

  width: 100%;

  padding: 12px;

  border: 1px solid #ccc;

  border-radius: 20px;

  font-size: 16px;

  transition: border-color 0.3s ease;

}



textarea {

  min-height: 100px;

  resize: vertical;

}



input[type="text"]:focus,

input[type="email"]:focus,

input[type="tel"]:focus,

input[type="url"]:focus,

input[type="date"]:focus,

textarea:focus,

select:focus {

  outline: none;

  border-color: #3788d8;

  box-shadow: 0 0 0 2px rgba(55, 136, 216, 0.2);

}



.checkbox-field,

.radio-field {

  display: flex;

  align-items: center;

  justify-content: center;

  margin-bottom: 10px;

}



.checkbox-field input,

.radio-field input {

  margin-right: 10px;

}



.social-media-field .social-media-platform {

  margin-bottom: 15px;

  text-align: left;

}



.navigation-buttons {

  display: flex;

  justify-content: space-between;

  margin-top: 30px;

}



button {

  padding: 12px 24px;

  background-color: #3788d8;

  color: white;

  border: none;

  border-radius: 20px;

  cursor: pointer;

  transition: background-color 0.3s ease, transform 0.1s ease;

  font-size: 16px;

}



button:hover {

  background-color: #2c6db3;

}



button:active {

  transform: scale(0.98);

}



.error {

  color: #ff4136;

  font-size: 0.9em;

  margin-top: 5px;

  text-align: center;

}



.results-container {

  background-color: #f9f9f9;

  padding: 30px;

  border-radius: 20px;

  text-align: center;

}



.score-bar {

  width: 100%;

  height: 20px;

  background-color: #e0e0e0;

  border-radius: 10px;

  margin: 20px 0;

  overflow: hidden;

}



.score-progress {

  height: 100%;

  background-color: #3788d8;

  border-radius: 10px;

  transition: width 0.5s ease-in-out;

}



.results-container ul {

  padding-left: 0;

  list-style-type: none;

}



.results-container li {

  margin-bottom: 10px;

  text-align: left;

}



.results-container button {

  margin-top: 30px;

}



.radio-options-vertical {

  display: flex;

  flex-direction: column;

  gap: 10px;

  margin-left: 20px;

  width: 100%;

}



.radio-options-vertical > div {

  display: flex;

  align-items: center;

  gap: 8px;

}



.radio-options-vertical input[type="radio"] {

  margin: 0;

}



.radio-options-vertical label {

  margin: 0;

  font-weight: normal;

}



.no-website-option {

  margin-top: 10px;

  display: flex;

  align-items: center;

}



.no-website-option input[type="checkbox"] {

  margin-right: 10px;

}



.info-text {

  font-size: 0.8em;

  color: #666;

  margin-top: 5px;

  display: block;

}



.results {

  background-color: #f9f9f9;

  padding: 30px;

  border-radius: 20px;

  text-align: left;

  margin-top: 20px;

}



.results h2, .results h3 {

  color: #3788d8;

}



.recommendations ul {

  padding-left: 20px;

}



.recommendations li {

  margin-bottom: 10px;

}



.cta-buttons {

  display: flex;

  justify-content: space-between;

  margin-top: 30px;

}



.ai-recommendations {

  background-color: #f0f8ff;

  border: 1px solid #b0d4ff;

  border-radius: 10px;

  padding: 20px;

  margin-top: 20px;

}



.ai-recommendations h3 {

  color: #3788d8;

  margin-top: 0;

}



.ai-recommendations pre {

  white-space: pre-wrap;

  word-wrap: break-word;

  font-family: Arial, sans-serif;

  line-height: 1.5;

}



.processing-visual {

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  height: 200px;

}



.spinner {

  border: 4px solid #f3f3f3;

  border-top: 4px solid #3788d8;

  border-radius: 50%;

  width: 40px;

  height: 40px;

  animation: spin 1s linear infinite;

}



@keyframes spin {

  0% { transform: rotate(0deg); }

  100% { transform: rotate(360deg); }

}



.processing-visual p {

  margin-top: 20px;

  font-size: 18px;

  color: #3788d8;

}



.first-step-loader {

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  padding: 2rem;

  text-align: center;

}



.first-step-loader .spinner {

  border: 4px solid #f3f3f3;

  border-top: 4px solid #3498db;

  border-radius: 50%;

  width: 40px;

  height: 40px;

  animation: spin 1s linear infinite;

  margin-bottom: 1rem;

}



.error-message {

  color: #ff0000;

  margin: 1rem 0;

  text-align: center;

}



@keyframes spin {

  0% { transform: rotate(0deg); }

  100% { transform: rotate(360deg); }

}
