.welcome-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 2rem;
}

h1 {
  color: #007bff;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

h2 {
  color: #0056b3;
  font-size: 1.8rem;
}

.intro {
  text-align: center;
  margin-bottom: 2rem;
}

.video-container {
  margin: 2rem 0;
}

.intro-video {
  width: 100%;
  max-width: 640px;
  box-shadow: 0 0 20px rgba(0, 0, 255, 0.3);
  border-radius: 8px;
}

.instructions {
  background-color: #e9ecef;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.instructions h3 {
  color: #007bff;
  margin-bottom: 1rem;
}

.instructions ol {
  padding-left: 1.5rem;
}

.instructions li {
  margin-bottom: 0.5rem;
}

.get-started-btn {
  display: block;
  width: 200px;
  margin: 0 auto;
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 25px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.get-started-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
